import { autoinject, observable } from 'aurelia-framework';
import { DialogController, DialogService } from "aurelia-dialog";
import { fhirEnums } from "../classes/fhir-enums";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import ResourceType = fhirEnums.ResourceType;
import RequestStatus = fhirEnums.RequestStatus;
import RequestIntent = fhirEnums.RequestIntent;
import { UserService } from "../services/UserService";
import { I18N } from "aurelia-i18n";
import { NitTools } from "../classes/NursitTools";
import { CarePlanService } from "../services/CarePlanService";
import ModalPlanningTimings from "./modal-planning-timings";
import { ConfigService } from "../services/ConfigService";
import ModalPlanningPrescription from "./modal-planning-prescription";
import { FhirService } from "../services/FhirService";
const QualificationCodes = require('./qualification-codes');
const moment = require("moment");

const ControlMeasureCodes = {
    '394758004': 'Independent provider',
    '125677006': 'Relative'
};

@autoinject
export default class ModalPlanningTask {
    dialogController;
    dialogService;
    userService;
    carePlanService: CarePlanService;
    i18n;
    task;
    patient;
    codeSystem;
    carePlan;
    procedureRequest;

    beginDate;
    @observable beginDateElement;
    endDate;
    @observable endDateElement;
    duration;
    timing = {
        type: 'interval',
        isRelative: false,
        unit: 'd',
        quantity: '1',
        preset: null
    };
    durationPresets = [];
    @observable isControlMeasure = false;
    controlMeasureChoice = '';

    isQualificationEnabled = false;
    qualificationChoice = '';

    isPrescriptionEnabled = false;
    isPrescriptionStrict = false;
    isTaskPrescriptionNeeded = false;
    isAllowAdditionalInstruction = false;
    isNewTimingPicker = false;
    newTimingPickerUrl = '';

    timingPickerIframeUrl = null;
    timingPickerIframeContainer = null;
    timingPickerIframeOrigin = null;
    timingPickerListener = null;
    timingPickerShow = false;
    isTimingPickerIframeReady = false;

    prescriptionPresent = 'not-needed';

    additionalInstruction = '';

    dpOptions = {
        format: RuntimeInfo.DateTimeFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: true,
        showClose: true,
        keepOpen: false,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false
    };

    get isSubmitDisabled() {
        return isNaN(this.duration) || (this.timing.unit === 'wk' && !this.procedureRequest.occurrenceTiming.repeat.dayOfWeek) || (this.isQualificationEnabled && !this.qualificationChoice && !this.isControlMeasure) || this.isNewTimingPicker && !this.isTimingPickerIframeReady;
    }

    constructor(dialogController: DialogController, dialogService: DialogService, userService: UserService, i18n: I18N, carePlanService: CarePlanService) {
        this.dialogController = dialogController;
        this.dialogService = dialogService;
        this.userService = userService;
        this.i18n = i18n;
        this.carePlanService = carePlanService;
    }

    activate(model) {
        this.patient = model.patient;
        this.codeSystem = model.codeSystem;
        this.carePlan = model.carePlan;

        const formConfig = ConfigService.GetFormSettings(ConfigService.FormNames.Planning);

        this.isQualificationEnabled = formConfig?.settings?.allowQualification;
        this.isPrescriptionEnabled = formConfig?.settings?.allowPrescription;
        this.isPrescriptionStrict = formConfig?.settings?.prescriptionNeededStrict;
        this.isAllowAdditionalInstruction = formConfig?.settings?.allowAdditionalInstruction;
        this.isNewTimingPicker = formConfig?.settings?.newTimingPicker?.enabled;
        this.newTimingPickerUrl = formConfig?.settings?.newTimingPicker?.url;

        if (this.isNewTimingPicker) {
            this.setupNewTimingPicker();
        }

        if (model.task) {
            this.task = model.task;
            this.duration = this.task.properties.defaultDuration;
            this.durationPresets = this.createDurationPresets();
            this.timing.preset = this.durationPresets[0];

            this.isTaskPrescriptionNeeded = this.task.properties.prescNeeded;

            if (this.isTaskPrescriptionNeeded) {
                this.prescriptionPresent = 'not-present';
            }

            this.procedureRequest = {
                id: 'urn:uuid:' + NitTools.Uid(),
                resourceType: (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest),
                asNeededBoolean: false,
                basedOn: [{ reference: "CarePlan/" + this.carePlan.id }],
                subject: { reference: `${ResourceType.patient}/${this.patient.id}` },
                [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `${ResourceType.encounter}/${this.patient.encounterId}` },
                status: RequestStatus.draft,
                intent: RequestIntent.plan,
                code: {
                    coding: [{
                        system: CarePlanService.getGmtCodeSystemUri(this.patient),
                        code: this.task.code,
                        display: this.task.display
                    }]
                },
                category: [{
                    coding: [{
                        system: 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type',
                        code: 'normal',
                        display: 'Normal'
                    }]
                }],
                occurrenceTiming: {
                    event: [],
                    repeat: {
                        duration: this.task.properties.defaultDuration
                    }
                },
                note: [{
                    text: ''
                }]
            };

            if (this.userService.practitioner) {
                this.procedureRequest.performer = { reference: "Practitioner/" + this.userService.practitioner.id };
            }

            this.presetChanged();
        } else if (model.procedureRequest) {
            this.procedureRequest = JSON.parse(JSON.stringify(model.procedureRequest));

            this.isTaskPrescriptionNeeded = this.codeSystem.properties.prescNeeded;

            if (this.procedureRequest.occurrenceTiming.event[0]) {
                this.beginDate = moment(this.procedureRequest.occurrenceTiming.event[0]);
            }

            if (this.procedureRequest.occurrenceTiming.event[1]) {
                this.endDate = moment(this.procedureRequest.occurrenceTiming.event[1]);
            }

            if (!this.procedureRequest.category) {
                this.procedureRequest.category = [];
            }

            const procedureRequestType = this.procedureRequest.category.find((category) => category.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type');

            this.isControlMeasure = procedureRequestType && procedureRequestType.coding[0].code === 'controlled';

            if (this.procedureRequest.performerType && this.procedureRequest.performerType.coding[0].system === 'http://snomed.info/sct') {
                this.controlMeasureChoice = this.procedureRequest.performerType.coding[0].code;
            } else if (this.procedureRequest.performerType && this.procedureRequest.performerType.coding[0].system === 'http://nursiti.com/CodeSystem/qualification') {
                this.qualificationChoice = this.procedureRequest.performerType.coding[0].code;
            }

            const prescriptionCategory = this.procedureRequest.category.find((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed');

            if (prescriptionCategory) {
                this.prescriptionPresent = prescriptionCategory.coding[0].code;
            }

            this.duration = this.procedureRequest.occurrenceTiming.repeat.duration;
            this.additionalInstruction = this.procedureRequest.note?.[0].text || '';

            this.durationPresets = this.createDurationPresets();
            this.timing.preset = this.tryFindSelectedPreset();
            this.presetChanged();
        }
    }

    setupNewTimingPicker() {
        if (!this.newTimingPickerUrl) {
            return;
        }

        this.timingPickerListener = (event) => {
            if (event.origin === this.timingPickerIframeOrigin) {
                switch (event.data.name) {
                    case 'getTimingData': {
                        if (this.timingPickerIframeContainer) {
                            this.isTimingPickerIframeReady = true;
                            this.timingPickerIframeContainer.contentWindow.postMessage({
                                name: 'getTimingData',
                                data: JSON.stringify(this.procedureRequest.occurrenceTiming)
                            }, this.timingPickerIframeUrl);
                        }
                        break;
                    }
                    case 'applyTimingData': {
                        this.procedureRequest.occurrenceTiming = JSON.parse(event.data.data);

                        this.duration = this.procedureRequest.occurrenceTiming.repeat.duration ? this.procedureRequest.occurrenceTiming.repeat.duration : 0;

                        this.beginDate = this.procedureRequest.occurrenceTiming.event[0] ? moment(this.procedureRequest.occurrenceTiming.event[0]).format(RuntimeInfo.DateTimeFormat) : null;

                        this.endDate = this.procedureRequest.occurrenceTiming.event[1] ? moment(this.procedureRequest.occurrenceTiming.event[1]).format(RuntimeInfo.DateTimeFormat) : null;

                        this.timingPickerShow = false;
                        break;
                    }
                }
            }
        };

        window.addEventListener("message", this.timingPickerListener);

        const query = {
            origin: location.origin
        };

        this.timingPickerIframeUrl = `${this.newTimingPickerUrl}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`;
        }).join('&');
        this.timingPickerIframeOrigin = this.newTimingPickerUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0];
    }

    toggleTimingPicker() {
        this.timingPickerShow = !this.timingPickerShow;
    }

    submit() {
        if (isNaN(this.duration)) {
            return;
        }

        if (this.task) {
            this.procedureRequest.authoredOn = moment().toJSON();
        }

        this.procedureRequest.note = [{ text: this.additionalInstruction }];
        this.procedureRequest.occurrenceTiming.repeat.duration = this.duration;

        if (this.timing.type === 'interval') {
            if (!this.isNewTimingPicker) {
                this.procedureRequest.occurrenceTiming.repeat.periodUnit = this.timing.unit;

                if (this.timing.isRelative) {
                    this.procedureRequest.occurrenceTiming.repeat.period = 0;
                    this.procedureRequest.occurrenceTiming.repeat.frequency = parseInt(this.timing.quantity, 10);
                } else {
                    this.procedureRequest.occurrenceTiming.repeat.period = parseInt(this.timing.quantity, 10);
                    this.procedureRequest.occurrenceTiming.repeat.frequency = 0;
                }

                delete this.procedureRequest.occurrenceTiming.code;
            }

            delete this.procedureRequest.asNeededBoolean;
        } else {
            const displays = {
                'at-release': 'At Release',
                'at-admission': 'At Admission',
                'as-needed': 'As Needed',
                'everlasting': 'Everlasting'
            };

            this.procedureRequest.occurrenceTiming.code = {
                coding: [{
                    system: 'http://nursit-institute.com/fhir/StructureDefinition/gmt-timing',
                    code: this.timing.type,
                    display: displays[this.timing.type]
                }]
            };

            if (this.timing.type === 'as-needed') {
                this.procedureRequest.asNeededBoolean = true;
            }

            delete this.procedureRequest.occurrenceTiming.repeat.periodUnit;
            delete this.procedureRequest.occurrenceTiming.repeat.period;
            delete this.procedureRequest.occurrenceTiming.repeat.frequency;
            delete this.procedureRequest.occurrenceTiming.repeat.timeOfDay;
            delete this.procedureRequest.occurrenceTiming.repeat.dayOfWeek;
        }

        if (!this.procedureRequest.category) {
            this.procedureRequest.category = [];
        }

        const procedureRequestTypeCategoryIndex = this.procedureRequest.category.findIndex((category) => category.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type');

        if (this.isControlMeasure) {
            const category = {
                coding: [{
                    system: 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type',
                    code: 'controlled',
                    display: 'Controlled'
                }]
            };

            if (procedureRequestTypeCategoryIndex > -1) {
                this.procedureRequest.category[procedureRequestTypeCategoryIndex] = category;
            } else {
                this.procedureRequest.category.push(category);
            }

            if (this.controlMeasureChoice) {
                this.procedureRequest.performerType = {
                    coding: [{
                        system: 'http://snomed.info/sct',
                        code: this.controlMeasureChoice,
                        display: ControlMeasureCodes[this.controlMeasureChoice]
                    }]
                };
            } else {
                delete this.procedureRequest.performerType;
            }
        } else {
            const category = {
                coding: [{
                    system: 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type',
                    code: 'normal',
                    display: 'Normal'
                }]
            };

            if (procedureRequestTypeCategoryIndex > -1) {
                this.procedureRequest.category[procedureRequestTypeCategoryIndex] = category;
            } else {
                this.procedureRequest.category.push(category);
            }

            if (this.qualificationChoice) {
                this.procedureRequest.performerType = {
                    coding: [{
                        system: 'http://nursiti.com/CodeSystem/qualification',
                        code: this.qualificationChoice,
                        display: QualificationCodes[this.qualificationChoice]
                    }]
                };
            } else {
                delete this.procedureRequest.performerType;
            }
        }

        if (this.isPrescriptionEnabled && (this.isTaskPrescriptionNeeded || this.prescriptionPresent !== 'not-needed')) {
            const procedureRequestPrescriptionCategoryIndex = this.procedureRequest.category.findIndex((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed');

            const category = {
                coding: [{
                    system: 'http://nursiti.com/CodeSystem/presc-needed',
                    code: this.prescriptionPresent,
                    display: this.prescriptionPresent
                }]
            };

            if (procedureRequestPrescriptionCategoryIndex > -1) {
                this.procedureRequest.category[procedureRequestPrescriptionCategoryIndex] = category;
            } else {
                this.procedureRequest.category.push(category);
            }

            if (this.prescriptionPresent !== 'present') {
                delete this.procedureRequest.supportingInfo;
            }
        } else {
            const procedureRequestPrescriptionCategoryIndex = this.procedureRequest.category.findIndex((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed');

            if (procedureRequestPrescriptionCategoryIndex > -1) {
                this.procedureRequest.category.splice(procedureRequestPrescriptionCategoryIndex, 1);
            }
        }

        this.dialogController.ok(JSON.parse(JSON.stringify(this.procedureRequest)));
    }

    delete() {
        this.dialogController.ok(null);
    }

    cancel() {
        this.dialogController.cancel();
    }

    presetChanged() {
        if (this.timing.preset.type === 'interval') {
            this.timing.type = this.timing.preset.type;

            if (!this.isNewTimingPicker) {
                if (this.timing.preset.frequency > 0) {
                    this.timing.isRelative = true;
                    this.timing.quantity = this.timing.preset.frequency.toString();
                    this.timing.unit = this.timing.preset.periodUnit;
                } else {
                    this.timing.isRelative = false;
                    this.timing.quantity = this.timing.preset.period.toString();
                    this.timing.unit = this.timing.preset.periodUnit;
                }
            }
        } else if (this.timing.preset.type !== 'custom') {
            this.timing.type = this.timing.preset.type;
        }
    }

    timingTypeChanged() {
        setTimeout(() => {
            this.procedureRequest.occurrenceTiming.code = undefined;

            if (this.timing.type === 'interval') {
                this.updateTimingPickerIframeContainer();
            }
        }, 0);
    }

    beginDateElementChanged() {
        this.beginDateElement.events.onChange = (e) => {
            // ugh
            setTimeout(() => {
                this.procedureRequest.occurrenceTiming.event[0] = moment(this.beginDate, RuntimeInfo.DateTimeFormat).toJSON();

                this.updateTimingPickerIframeContainer();
            }, 0);
        };
    }

    endDateElementChanged() {
        this.endDateElement.events.onChange = (e) => {
            setTimeout(() => {
                this.procedureRequest.occurrenceTiming.event[1] = moment(this.endDate, RuntimeInfo.DateTimeFormat).toJSON();

                this.updateTimingPickerIframeContainer();
            }, 0);
        };
    }

    updateTimingPickerIframeContainer() {
        if (this.newTimingPickerUrl && this.timingPickerIframeContainer) {
            this.timingPickerIframeContainer.contentWindow.postMessage({
                name: 'updateTimingData',
                data: JSON.stringify(this.procedureRequest.occurrenceTiming)
            }, this.timingPickerIframeUrl);
        }
    }

    tryFindSelectedPreset() {
        const preset = this.durationPresets.find((dPreset) => {
            if (this.procedureRequest.occurrenceTiming.code) {
                if (this.procedureRequest.occurrenceTiming.code.coding[0].code === dPreset.type) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return (this.procedureRequest.occurrenceTiming.repeat.periodUnit === dPreset.periodUnit && this.procedureRequest.occurrenceTiming.repeat.frequency === dPreset.frequency && this.procedureRequest.occurrenceTiming.repeat.period === dPreset.period);
            }
        });

        if (preset) {
            return preset;
        } else {
            if (this.procedureRequest.occurrenceTiming.code) {
                this.timing.type = this.procedureRequest.occurrenceTiming.code.coding[0].code;
            } else {
                this.timing.type = 'interval';

                if (this.procedureRequest.occurrenceTiming.repeat.frequency > 0) {
                    this.timing.isRelative = true;
                    this.timing.quantity = this.procedureRequest.occurrenceTiming.repeat.frequency.toString();
                    this.timing.unit = this.procedureRequest.occurrenceTiming.repeat.periodUnit;
                } else {
                    this.timing.isRelative = false;
                    this.timing.quantity = this.procedureRequest.occurrenceTiming.repeat.period.toString();
                    this.timing.unit = this.procedureRequest.occurrenceTiming.repeat.periodUnit;
                }
            }

            return this.durationPresets[this.durationPresets.length - 1];
        }
    }

    createDurationPresets() {
        const durations = this.codeSystem.durations.map((duration) => {
            let text, unit;

            if (duration.type === 'interval') {
                if (duration.periodUnit === 'd') {
                    unit = this.i18n.tr('days');
                } else if (duration.periodUnit === 'h') {
                    unit = this.i18n.tr('hours');
                } else if (duration.periodUnit === 'min') {
                    unit = this.i18n.tr('minutes');
                }

                if (duration.frequency > 0) {
                    text = this.i18n.tr('duration_frequency', { amount: duration.frequency, unit });
                } else {
                    text = `alle ${duration.period} ${unit}`;
                }

                return {
                    type: duration.type,
                    periodUnit: duration.periodUnit,
                    frequency: duration.frequency,
                    period: duration.period,
                    text
                };
            } else {
                const displays = {
                    'at-release': this.i18n.tr('0_atRelease'),
                    'at-admission': this.i18n.tr('0_atAdmission'),
                    'as-needed': this.i18n.tr('0_whenNeeded'),
                    'everlasting': this.i18n.tr('0_everlasting')
                };

                return {
                    type: duration.type,
                    text: displays[duration.type]
                };
            }
        });

        durations.push({
            type: 'custom',
            text: this.i18n.tr('custom')
        });

        return durations;
    }

    configureRelativeTimings() {
        this.dialogService.open({
            viewModel: ModalPlanningTimings,
            model: {
                procedureRequest: this.procedureRequest,
                timing: this.timing
            },
            lock: true
        });
    }

    relativePropChanged() {
        if (this.procedureRequest.occurrenceTiming.repeat) {
            delete this.procedureRequest.occurrenceTiming.repeat.timeOfDay;

            this.procedureRequest.occurrenceTiming.repeat.dayOfWeek = null;
        }
    }

    async selectPrescription() {
        this.dialogService.open({
            viewModel: ModalPlanningPrescription,
            model: {
                codeSystem: await this.carePlanService.getGMTCodeSystem(this.patient),
                patient: this.patient,
                procedureRequest: this.procedureRequest
            },
            lock: true
        }).whenClosed(async (dialogResult) => {
            if (dialogResult.wasCancelled) return;

            this.procedureRequest.supportingInfo = [{
                reference: `Media/${dialogResult.output}`
            }];
        });
    }

    durationChanged() {
        if (!this.duration || this.duration < 0) {
            this.duration = 0;
        }

        this.procedureRequest.occurrenceTiming.repeat.duration = this.duration;

        this.updateTimingPickerIframeContainer();
    }

    isControlMeasureChanged(v) {
        if (v) {
            this.qualificationChoice = '';
        }
    }
}
